<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Technical Presentations
"
      size="large"
    />
    <Paragraph
      ><p>
        “Prediction of the Full-Scale Cook-off Response Based on Small-Scale
        Testing” PARARI · Brisbane, Australia · Nov. 2011 · Robert T. Ford, A.
        Garn Butcher &amp; Clint G. Guymon, PhD
      </p>
      <p>
        “In-Process Material Classification” IGUS Conference · SLC, UT · May
        2011 · A. Garn Butcher &amp; Kirt N. Sasser
      </p>
      <p>
        “Principles and Methods of Sensitivity Testing for In-Process Hazard
        Characterization of Explosive Materials” DDESB · Portland, OR · July,
        2010 · Robert T. Ford &amp; Kirt N. Sasser · PARARI · Brisbane,
        Australia · Nov. 2011 · Robert T. Ford
      </p>
      <p>
        “Bulk Thermal Stability Characterization via the SBAT Apparatus” DDESB ·
        Portland, OR · July, 2010 · Clint G. Guymon &amp; Robert T. Ford · IGUS
        Conference · SLC, UT · May 2011 · Clint G. Guymon, PhD &amp; Jared S.
        Teter, PhD
      </p>
      <p>
        “Application of Safety-By-Design To The Future AP Rocket Motor Demil
        (ARMD) Facility At Letterkenny Munitions Center” Global Demil Symposium
        and Exposition · Denver, CO · May 2011 · Robert T. Ford &amp; Gary D.
        Dodds
      </p>
      <p>
        “Successful Integration of Hazards Analysis Methodology into the Design
        of a Demilitarization Inductive Heating Meltout (DIHME) Operation”
        Global Demil Symposium and Exposition · Tulsa, OK · May 2010 · Robert T.
        Ford &amp; Jason D. Howe
      </p>
      <p>
        “Successful Integration of Hazards Analysis Methodology into the Design,
        Startup, and Operation of a Munitions Residue Inspection System (MRIS)”
        Global Demil Symposium and Exposition · Tulsa, OK · May 2010 · Robert T.
        Ford &amp; Jason D. Howe
      </p>
      <p>
        “Risk Analysis Applied to Cutting Process Piping Contaminated with
        Explosive Materials” · PARARI · Adelaide, South Australia · Nov. 2009 ·
        Robert T. Ford
      </p>
      <p>
        “Safe Decontamination and Demolition of Explosives-processing
        Facilities” · DDESB · Portland, OR · July 2010 · PARARI · Adelaide,
        South Australia · Nov. 2009 · Robert T. Ford
      </p>
      <p>
        “Part II: Explosives Safety Principles Successfully Applied to
        Decommissioning of Operating Buildings” Global Demil Symposium and
        Exposition · San Diego, CA · May 2009 · Robert T. Ford &amp; Patrick
        Keller
      </p>
      <p>
        “Explosives Safety Principles Successfully Applied to Cutting Process
        Piping Contaminated with Explosive Materials” Global Demil Symposium and
        Exposition · San Diego, CA · May 2009 · Robert T. Ford &amp; Ray
        Jorgenson
      </p>
      <p>
        “Explosive Safety Principles Applied to Decontamination &amp; Demolition
        of Facilities” · DDESB · Palm Springs, CA · Aug. 2008 · Robert T. Ford
        &amp; Kirt N. Sasser
      </p>
      <p>
        “Explosive Safety Principles Successfully Applied to Decontamination and
        Demolition of Explosives-processing Facilities” Global Demil Symposium
        and Exposition · SLC, UT · May 2008 · Robert T. Ford · PARARI ·
        Melbourne, Australia · Nov. 2007 · Robert T. Ford
      </p>
      <p>
        “Successful Integration of Hazards Analysis Methodology into the Design,
        Startup, and Operation of a Cryofracture Demilitarization Facility”
        Global Demil Symposium and Exposition · Reno, NV · May 2007 · Jason D.
        Howe
      </p>
      <p>
        “Ergonomic Principles and Risk Assessment” University of Utah · SLC, UT
        · April 2007 · Kirt N. Sasser
      </p>
      <p>
        “Risk Management Principles Applied to Energetic Material Processing”
        University of Utah · SLC, UT · April 2007 · Robert T. Ford
      </p>
      <p>
        “Explosives Operating Building Decontamination &amp; Demolition” ATK
        Launch Systems · Feb. 2007 · Robert T. Ford.
      </p>
      <p>
        “Enhancing Root Cause Analysis Effectiveness and Efficiency” ATK
        Conference · Nov. 2006 · Robert T. Ford
      </p>
      <p>
        “PSM Regulation Applicability for Explosive Operations Overview,” SPISC
        · ATK Elkton, MD · June 2006 · Robert T. Ford
      </p>
      <p>
        “The Hazards Analysis Process from Start to Finish” ATK Corporate · Jan.
        2006 · Robert T. Ford
      </p>
      <p>
        “Sensitivity &amp; Reactivity Characterization Testing of Energetic
        Material for Process Risk Assessment” JANNAF · Monterery, CA · Dec. 2005
        · Robert T. Ford &amp; A. Garn Butcher
      </p>
      <p>
        “Standardization of Sensitivity and Reactivity Characterization Testing
        for Energetic Materials Processing” PARARI · Brisbane, Australia · Nov
        2005
      </p>
      <p>
        “Root Cause Analysis for Accident Investigation” ES&amp;H Conference ·
        ATK · Sept. 2004 · Robert T. Ford
      </p>
      <p>
        “Successful Integration of Hazards Analysis Methodology into the Design
        of a Chemical Agent Munition Demilitarization Facility” Global Demil
        Symposium and Exposition · Lexington, KY · May 2002 · Robert T. Ford
        &amp; Gary D. Dodds
      </p>
      <p>
        “Risk-Based Assessment in an Energetic Material Processing Facility:
        Avoiding Overly-Conservative Guidelines for Transient Traffic,” ’99
        Global Demilitarization Symposium and Exposition, May 1999, Robert T.
        Ford &amp; Lyman A. Losee.
      </p>
      <p>
        “Applying a Systematic Approach to Risk and Cost Evaluation,” ASSE Best
        Practices, February 2, 1999, Robert T. Ford.
      </p>
      <p>
        “Risk-Based Assessment for Microwave Meltout Demilitarization of M117
        Bombs,” ADPA Global Demil, Coeur d’ Alene, ID, May 1998, Robert T. Ford
        &amp; Jay L. Potter.
      </p>
      <p>
        “Risk-Based Assessment in an Energetic Material Processing Facility,”
        DDESB, Orlando, FL, August 1998, Robert T. Ford &amp; Lyman A. Losee.
      </p>
      <p>
        “Safety Management by Risk-Based Assessment,” JANNAF CS, PSHA, and APS
        Joint Meeting, West Palm Beach, FL, October 19, 1997, Robert T. Ford
        &amp; Lyman A. Losee.
      </p>
      <p>
        “Demilitarization of M117 Bombs: A Risk-Based Assessment Success Story,”
        ALChE, New Orleans, LA, March 1998, and JANNAF, Houston TX, April 1998,
        Robert T. Ford &amp; Boyd Hill.
      </p>
      <p>
        “Mobile Generant Loader and Venting System,” GES/SMS Morton
        Presentation, June 5, 1997, Robert T. Ford &amp; Thaddeus C. Speed.
      </p>
      <p>
        “Remediation of Explosives Contaminated Soil and Piping; a Risk-Based
        Assessment Success Story,” UXO Forum 1997 at the Opryland Hotel,
        Nashville, TN, May 28-30, 1997, Robert T. Ford &amp; Karla A. Hendricks.
      </p>
      <p>
        “Risk/Cost Trade-Off Analysis,” Safety Summit 1998, Global Solutions for
        People, Property, and the Environment, Seattle, WA, June 12-14, 1998,
        Robert T. Ford.
      </p>
      <p>
        “Risk Trade-Off Analysis,” Risk Management Symposium, Risk Management
        and Decision Making, Session No. 14, New Orleans, LA, February 27, 1996,
        Robert T. Ford.
      </p>
    </Paragraph>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Technical Presentations",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content: "A compilation of technical presentations."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.about {
}
</style>
